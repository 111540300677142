import axios from "axios";
import configData from "../config.json";
import { authProvider } from "./AuthProvider";

const isAdminUserCheck = async function () {
  try {
    const { accessToken } = await authProvider.getGatewayAccessToken();
    const sysId = window.sessionStorage.getItem("userEmailID");
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const openidAccessToken = await authProvider.getAccessToken();
    var data = {
      uid: sysId,
      accessToken: openidAccessToken.accessToken,
    };
    const workforceDetails = await axios.post(
      configData.CheckAdminbyID,
      data,
      config
    );
    let adMemberships = JSON.parse(workforceDetails.data.body);
    let isAdmin = adMemberships.filter(function (el) {
      return el.displayName == configData.Admin_FIM;
    });
    if (isAdmin.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export default isAdminUserCheck;
