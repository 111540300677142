import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cookies from 'js-cookie'; // Import js-cookie for cookie handling
import LillyLogo from './images/logo.svg'; // Adjust the path accordingly

const ConfirmationModal = ({ show, handleClose, handleYesClick }) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [imageLoaded,setImageLoaded] = useState(false);

  useEffect(() => {
    // Check if the modal has been shown before by checking the cookie
    const modalShown = Cookies.get('confirmationModalShown');
    if (!modalShown) {
      const img = new Image();
      img.src = LillyLogo;
      img.onload = () => { setImageLoaded(true);

      setShouldShowModal(true);
      }
    }
  }, []);

  const handleCloseWithRedirection = () => {
    // Redirect to the desired URL
    window.location.href = "https://www.lilly.com/jp/";
  };

  const handleYesClickWithStorage = () => {
    Cookies.set('confirmationModalShown', 'true', { expires: 1 });
    handleYesClick(); // Call handleYesClick prop
  };

  if (!shouldShowModal) {
    return null; // Render nothing if the modal shouldn't be shown
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" className="custom-modal" centered>
      <Modal.Header className="modal-header">
        <br/>
        <img src={LillyLogo} alt="Lilly Logo" className="logo-image" />
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p className="thankyou"><h4>日本イーライリリー　プレスリリース　ページ</h4></p>
        <p className="bg-black">このページは報道関係者の方を対象としており、<br/> 一般の方に対する情報提供を目的としたものではないことをご了承ください。</p>
        <p className="bg-bold-black">あなたは、報道関係者ですか？</p>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="button-container">
          <Button variant="secondary" onClick={handleCloseWithRedirection} className="no-button">
            いいえ。（コーポレートサイトへ）
          </Button>
          <Button
            variant="primary"
            onClick={handleYesClickWithStorage}
            className="yes-button"
            style={{
              backgroundColor: '#d52b1e',
              color: 'white',
              border: '2px solid #d52b1e'
            }}
          >
            はい。報道関係者です。
          </Button>
        </div>
        <br/>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
