import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/App.css";
import "../css/core.css";
import logo from "../../logo.svg";

function Header() {
  var pathname = window.location.pathname;
  if (pathname == "/jp") {
    return (
      <div id="header" className="ldc-sticky-header lds-sticky top">
        <div className="lds-sticky-placeholder"></div>
        <div className="lds-sticky-container" id="stickyHeader">
          <header
            id="header-id"
            className="lds-header lds-header-align-right lds-header-has-exo"
          >
            <div className="lds-header-main wrapper">
              <div className="lds-header-logo-wrapper">
                <a
                  href="https://www.lilly.co.jp/"
                  target="_blank"
                  className="lds-link lds-logo-lilly logo-md logo-red"
                >
                  {" "}
                  <span className="mr-5">
                    <img src={logo} alt="Lilly" width="90" height="60" />
                  </span>
                </a>{" "}
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  } else {
    return (
      <div id="header" className="ldc-sticky-header lds-sticky top">
        <div className="lds-sticky-placeholder"></div>
        <div className="lds-sticky-container" id="stickyHeader">
          <header
            id="header-id"
            className="lds-header lds-header-align-right lds-header-has-exo"
          >
            <div className="lds-header-main wrapper">
              <div className="lds-header-logo-wrapper">
                <a
                  href="/jp/admin"
                  className="lds-link lds-logo-lilly logo-md logo-red"
                >
                  {" "}
                  <span className="mr-5">
                    <img src={logo} alt="Lilly" width="90" height="60" />
                  </span>
                </a>{" "}
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}

export default Header;
