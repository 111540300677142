import React, { useState, useEffect, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../css/App.css";
import "../css/core.css";
import Header from "components/Header/header";
import Footer from "components/Footer/Footer";

function Content() {
  const [Data, setData] = useState([]);

  useEffect(() => {
    fetch("../../../metadata/metadata.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        myJson.sort(custom_sort).reverse();
        myJson = myJson.filter((l) => {
          return l.status.match("Published");
        });
        setData(myJson);
      });
  }, []);

  function custom_sort(a, b) {
    return new Date(a.spt) - new Date(b.spt);
  }

  const yearlist = [];
  let currentyear = new Date().getFullYear();

  for (var i = 1; i < 7; i++) {
    yearlist.push(currentyear - i);
  }

  return (
    <Fragment>
      <Header />
      <div className="page-container">
        <div className="container page-content">
          <div>
            <div className="row mt48">
              <div className="col-sm-12">
                <h1 id="mainheading">
                  プレスリリース
                  <br />
                  <sup id="headingtitle">ニュース 報道関係者向け</sup>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="p-2 col-lg-11 col-md-11 col-sm-12">
                  <div>
                    {Data.map((post, index) => {
                      let date = post.Release.split("-");
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      for (const param of params) {
                        if (!isNaN(param[1]) && param[0] === "year") {
                          currentyear = parseInt(param[1]);
                          const title =
                            "ニュース バックナンバー" +
                            `${currentyear}` +
                            "年度";
                          setTimeout(function () {
                            document.getElementById("headingtitle").innerText =
                              title;
                          }, 1000);
                        } else if (
                          !isNaN(param[1]) &&
                          param[0] === "category" &&
                          parseInt(param[1]) !== 7
                        ) {
                          if (parseInt(param[1]) === 18) {
                            currentyear = 2020;
                          }
                          if (parseInt(param[1]) === 13) {
                            currentyear = 2019;
                          }
                          if (parseInt(param[1]) === 12) {
                            currentyear = 2018;
                          }
                          if (parseInt(param[1]) === 3) {
                            currentyear = 2017;
                          }
                          if (parseInt(param[1]) === 4) {
                            currentyear = 2016;
                          }
                          if (parseInt(param[1]) === 5) {
                            currentyear = 2015;
                          }
                          const title =
                            "ニュース バックナンバー" +
                            `${currentyear}` +
                            "年度";
                          setTimeout(function () {
                            document.getElementById("headingtitle").innerText =
                              title;
                          }, 1000);
                        } else if (
                          (param[1] === "finance" ||
                            parseInt(param[1]) === 7) &&
                          param[0] === "category"
                        ) {
                          const title = "業績発表";
                          setTimeout(function () {
                            document.getElementById("mainheading").innerHTML =
                              title;
                          }, 300);

                          return (
                            <>
                              {post.category === "業績発表" ? (
                                <div key={index}>
                                  <h3>
                                    {date[0]}年{date[1]}月{date[2]}日
                                  </h3>
                                  <p>
                                    <p>
                                      <a
                                        className="pdf latest-story"
                                        href={"/jp/previewPDF/" + post.Year + "/" + post.FileName}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "anywhere",
                                        }}
                                      >
                                        {post.Title}{" "}
                                      </a>
                                    </p>
                                  </p>
                                </div>
                              ) : null}
                            </>
                          );
                        }
                      }
                      return (
                        <>
                          {parseInt(post.Year) === currentyear ? (
                            <div key={index}>
                              <h3>
                                {date[0]}年{date[1]}月{date[2]}日
                              </h3>
                              <p>
                                <a
                                  className="pdf latest-story"
                                  href={"/jp/previewPDF/" + post.Year + "/" + post.FileName}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {post.Title}{" "}
                                </a>
                              </p>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 d-none d-md-block">
                <ul className="side-navigation p-2">
                  <li key={0}>
                    <a className="active" href="/jp">プレスリリース</a>
                  </li>
                  {yearlist.map((comp, index) => (
                    <li key={index + 1}>
                      <a href={"?year=" + comp}>バックナンバー{comp}年度</a>
                    </li>
                  ))}
                  <li>
                    <a href="?category=finance">業績発表</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Content;
