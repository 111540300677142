import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Header from "components/Header/header";
import Footer from "components/Footer/Footer";
import configData from "../../config.json";
import { authProvider } from "../../components/AuthProvider";
import "../../pages/NewData/index.css";
import "../../pages/AdminData/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "react-bootstrap";

const EditUser = () => {
  document.title = "プレスリリース | 管理画面";
  let history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useState({
    DocID: "",
    Release: "",
    Title: "",
    category: "",
    spt: "",
    status: "",
    modby: "",
    mod: "",
    FileName: "",
    Year: "",
  });
  const [errors, setError] = useState([]);
  const { DocID, Release, Title, category, spt, status } = user;
  const today = new Date();
  const time = moment(today).format("HH:mm");
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkInTime, setCheckInTime] = useState(time);
  const [disabled, setDisabled] = useState(false);
  const [statuses, setStatus] = useState("");
  const [uploadfilenew, setUploadfilenew] = useState({
    selectedFile: "",
    loaded: 0,
  });

  const onChangeHandler = (event) => {
    document.getElementById("form.browse").style.color = "black";
    setUploadfilenew({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  const validate = async (Title, status, DocID, btnType) => {
    const errors = [];
    if (Title.length === 0 && status != "Draft") {
      errors.push("タイトルを記入してください");
    }
    if (status.length === 0 && status != "Draft") {
      errors.push("続行するにはスケジュールを選択してください");
    }
    var numberPattern = /^\d{4,5}/;
    if ((DocID && !DocID.toString().match(numberPattern)) || !DocID) {
      errors.push("4〜5桁の有効なDocIDを入力してください");
    }
    if (uploadfilenew.loaded === 0 && !user.FileName && status != "Draft") {
      errors.push("ファイルを選択してUploadをクリックしてください。");
    }
    let currentDatetime = new Date();
    let currentTime = moment(currentDatetime).add(1, "minutes").format("HH:mm");
    let ifScheduled = document.getElementById("Schedule").checked;
    let ifPublished = document.getElementById("Publish").checked;
    let currentDate = `${moment(currentDatetime).format("YYYY-MM-DD")}`;
    let checkInTime_new = moment(new Date(spt)).format("HH:mm");
    if (
      (!ifScheduled && !ifPublished && btnType == "notDraft") ||
      (checkInTime_new <= currentTime &&
        status == "Scheduled" &&
        checkInDate <= currentDatetime)
    ) {
      errors.push("現在以降の日時を選択してください。");
    }
    if (ifPublished && Release > currentDate) {
      errors.push(
        "Publish nowの場合は、今日または今日より前の日時を選択してください"
      );
    }
    return errors;
  };

  const handleCheckInDate = (e) => {
    setCheckInDate(e);
    var releaseYear = `${moment(e).format("YYYY-MM-DD")}`;
    releaseYear = releaseYear.split("-");
    setUser({
      ...user,
      Release: `${moment(e).format("YYYY-MM-DD")}`,
      spt: `${moment(e).format("YYYY-MM-DD")} ${checkInTime}`,
      Year: releaseYear[0],
      mod: new Date(),
      modby: `${window.sessionStorage.getItem("loggedinUserName")}`,
    });
  };

  const handleCheckInTime = (e) => {
    setCheckInTime(e.target.value);
    setUser({
      ...user,
      Release: `${moment(checkInDate).format("YYYY-MM-DD")}`,
      spt: `${moment(checkInDate).format("YYYY-MM-DD")} ${e.target.value}`,
      mod: new Date(),
      modby: `${window.sessionStorage.getItem("loggedinUserName")}`,
    });
  };

  const onInputChange = (e) => {
    var updatedCategory = category;
    var ifPublished = false;
    if (e.target.name == "category") {
      if (e.target.checked) {
        updatedCategory = "業績発表";
      } else {
        updatedCategory = "一般";
      }
    }
    if (e.target.value === "Published") {
      setDisabled(true);
      document.getElementById("publishbtn").innerText = "Publish";
      ifPublished = true;
    }
    if (e.target.value === "Scheduled") {
      setDisabled(false);
      document.getElementById("publishbtn").innerText = "Schedule";
    }
    var releaseYear = user.Release.split("-");
    if (!releaseYear.length > 0) {
      releaseYear = `${moment(checkInDate).format("YYYY-MM-DD")}`;
      releaseYear = releaseYear.split("-");
    }
    var scheduletime;
    if (!ifPublished) {
      scheduletime = moment(new Date(spt)).format("HH:mm");
      setCheckInTime(scheduletime);
    } else {
      scheduletime = checkInTime;
    }
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      mod: new Date(),
      Year: releaseYear[0],
      category: `${updatedCategory}`,
      modby: `${window.sessionStorage.getItem("loggedinUserName")}`,
      Release: `${moment(checkInDate).format("YYYY-MM-DD")}`,
      spt: `${moment(checkInDate).format("YYYY-MM-DD")} ${scheduletime}`,
    });
  };

  const unSetLocal = async () => {
    // localStorage.clear();
    // sessionStorage.clear();
  };

  const publishData = async (e) => {
    const errors = await validate(Title, status, DocID, "notDraft");
    if (errors.length > 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return;
    } else {
      let newArray = { ...user };
      let statusparam =
        document.getElementById("Publish").checked == true
          ? "Published"
          : "Scheduled";
      newArray = { ...newArray, status: statusparam };
      const { accessToken } = await authProvider.getGatewayAccessToken();
      var config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setUser(newArray);
      await axios.put(`${configData.MetaDatabyID}/${id}`, newArray, config);
      history.push("/jp/admin");
    }
  };

  const backData = async (e) => {
    history.push("/jp/admin");
  };

  const updateDraft = async (e) => {
    const errors = await validate(Title, status, DocID, "Draft");
    if (errors.length > 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return;
    } else {
      let newArray = { ...user };
      newArray = { ...newArray, status: "Draft" };
      const { accessToken } = await authProvider.getGatewayAccessToken();
      var config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setUser(newArray);
      await axios.put(`${configData.MetaDatabyID}/${id}`, newArray, config);
      history.push("/jp/admin");
    }
  };

  const sendDataToNewTab = async (e) => {
    unSetLocal();
    localStorage.setItem("pageData", JSON.stringify(user));
    const errors = await validate(Title, status, DocID, "notDraft");
    if (errors.length > 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return;
    } else {
      // your axios call here
      sessionStorage.setItem("pageData", JSON.stringify(user));
      window.open(
        "/jp/preview",
        "preview",
        "width=1200,height=500,top=50,left=50"
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { accessToken } = await authProvider.getGatewayAccessToken();
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios.put(`${configData.MetaDatabyID}/${id}`, user, config);
    history.push("/jp/admin");
  };

  const previewPDF = async (e) => {
    e.preventDefault();
    const { accessToken } = await authProvider.getGatewayAccessToken();
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${configData.previewPDF}?year=${user.Year}&filename=${user.FileName}`,
      config
    );
    var buffer, d, i, result;
    // 1. convert object to array
    result = [];
    for (i in response.data.Body.data) {
      d = response.data.Body.data[i];
      result.push(d);
    }
    // 2. convert that to a Uint8Array
    buffer = new Uint8Array(result);
    var blob = new Blob([buffer], { type: "application/pdf" });
    var link = window.URL.createObjectURL(blob);
    window.open(
      link,
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    );
  };

  const onClickHandler = async (e) => {
    const data = new FormData();
    var releaseYear = user.Release.split("-");
    if (!releaseYear.length > 0) {
      releaseYear = `${moment(checkInDate).format("YYYY-MM-DD")}`;
      releaseYear = releaseYear.split("-");
    }
    data.append("year", releaseYear[0]);
    data.append("docid", user.DocID);
    data.append("file", uploadfilenew.selectedFile);
    const { accessToken } = await authProvider.getGatewayAccessToken();
    setUploadfilenew({
      loaded: 1,
    });
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios.post(configData.fileUploadapi, data, config).then((res) => {
      // then print response status
      setUser({
        ...user,
        FileName: uploadfilenew.selectedFile.name,
        Year: releaseYear[0],
      });
    });
  };

  const loadUser = async () => {
    if (id == "undefined") {
      history.push("/jp/error");
    }
    const { accessToken } = await authProvider.getGatewayAccessToken();
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(`${configData.MetaDatabyID}/${id}`, config);
    const alldata = result.data[0];
    setUser({ ...alldata });
    localStorage.setItem("statuss", JSON.stringify(alldata.status));
    sessionStorage.setItem("statuss", JSON.stringify(alldata.status));
    sessionStorage.setItem("Release", JSON.stringify(alldata.Release));
    setStatus(alldata.status);
    var Releasedate = JSON.stringify(alldata.Release);
    setCheckInDate(new Date(Releasedate));
  };

  useEffect(() => {
    loadUser();
  }, []);

  setTimeout(function () {
    var statLabel = JSON.parse(window.sessionStorage.getItem("statuss"));
    var btnText = document.getElementById("publishbtn").innerText;
    if (statLabel == "Published") {
      document.getElementById("Publish").checked = true;
      document.getElementById("Schedule").disabled = true;
      document.getElementById("publishbtn").innerText = "Publish";
      setDisabled(true);
    } else if (statLabel == "Scheduled" && btnText != "Publish") {
      document.getElementById("Schedule").checked = true;
      document.getElementById("Publish").disabled = false;
      document.getElementById("publishbtn").innerText = "Schedule";
      setDisabled(false);
    }
    if (user.FileName != "" && uploadfilenew.selectedFile == "") {
      document.getElementById("form.browse").style.color = "white";
    }
  }, 1500);

  return (
    <>
      <div>
        <Header />
      </div>
      <div className=" w-100">
        <div className="col mb-3 col-12 text-center">
          <div className="row">
            {!(
              window.location.pathname.substring(
                6,
                window.location.pathname.lastIndexOf("/") + 0
              ) ==
              window.location.pathname.substring(
                window.location.pathname.lastIndexOf("/") + 1,
                window.location.pathname.length
              )
            ) ? (
              <div className="col-2 border-right">
                <Link to="/jp/admin">Home</Link>
              </div>
            ) : null}
            <div
              className={
                !(
                  window.location.pathname.substring(
                    6,
                    window.location.pathname.lastIndexOf("/") + 0
                  ) ==
                  window.location.pathname.substring(
                    window.location.pathname.lastIndexOf("/") + 1,
                    window.location.pathname.length
                  )
                )
                  ? "col-10"
                  : "col-12"
              }
            >
              <div className="d-flex justify-content-start mb-3 mt-4">
                <div className="container editUser">
                  {window.location.pathname.substring(
                    6,
                    window.location.pathname.lastIndexOf("/") + 0
                  ) ==
                  window.location.pathname.substring(
                    window.location.pathname.lastIndexOf("/") + 1,
                    window.location.pathname.length
                  ) ? (
                    <>
                      <div className="row mt48 text-left">
                        <div className="col-sm-9">
                          <h1>
                            プレスリリース
                            <br />
                            <sup>ニュース</sup>
                          </h1>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-lg-9 col-md-8 col-sm-12">
                          <div className="p-2 col-lg-11 col-md-11 col-sm-12">
                            <h3>
                              {" "}
                              {moment(
                                JSON.parse(
                                  window.sessionStorage.getItem("pageDatas")
                                ).spt
                              ).format("MMMM Do, YYYY")}
                            </h3>
                            <p>
                              <a
                                className="pdf latest-story"
                                href="javascript:void(0)"
                                target="_blank"
                              >
                                {
                                  JSON.parse(
                                    window.sessionStorage.getItem("pageDatas")
                                  ).Title
                                }
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-none d-md-block">
                          <ul className="side-navigation p-2">
                            {" "}
                            <li>
                              <a
                                className="active"
                                href="/jp/news_list.html?page=1&amp;category=19"
                              >
                                {" "}
                                プレスリリース{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=18">
                                {" "}
                                バックナンバー2020年度{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=13">
                                {" "}
                                バックナンバー2019年度{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=12">
                                {" "}
                                バックナンバー2018年度{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=3">
                                {" "}
                                バックナンバー2017年度{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=4">
                                {" "}
                                バックナンバー2016年度{" "}
                              </a>
                            </li>{" "}
                            <li>
                              <a href="/jp/news_list.html?page=1&amp;category=5">
                                {" "}
                                バックナンバー2015年度{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {}
                  <Form>
                    {errors.map((error) => (
                      <p className="mb-3" key={error}>
                        Error: {error}
                      </p>
                    ))}
                    {!(
                      window.location.pathname.substring(
                        6,
                        window.location.pathname.lastIndexOf("/") + 0
                      ) ==
                      window.location.pathname.substring(
                        window.location.pathname.lastIndexOf("/") + 1,
                        window.location.pathname.length
                      )
                    ) ? (
                      <>
                        <Form.Group as={Row} className="mb-3 fromSchedule">
                          <Form.Label as="legend" column sm={2}>
                            Schedule*
                          </Form.Label>
                          <Row>
                            <Form.Check
                              required
                              type="radio"
                              label="Set a schedule"
                              name="status"
                              id="Schedule"
                              value="Scheduled"
                              onChange={(e) => onInputChange(e)}
                            />
                            <Form.Check
                              required
                              onChange={(e) => onInputChange(e)}
                              type="radio"
                              label="Publish now"
                              name="status"
                              id="Publish"
                              value="Published"
                            />
                          </Row>
                        </Form.Group>
                        <InputGroup className="mb-3">
                          <Form.Label column sm="2">
                            Release Date & Time*
                          </Form.Label>
                          <Form.Group controlId="reldate">
                            <DatePicker
                              type="date"
                              selected={checkInDate}
                              minDate={
                                disabled == true
                                  ? new Date("10/10/2014")
                                  : new Date()
                              }
                              maxDate={
                                disabled == true
                                  ? new Date()
                                  : new Date("10/10/2100")
                              }
                              onChange={(e) => handleCheckInDate(e)}
                              name="Release"
                              className="dtpicker"
                              closeOnScroll={(e) => e.target === document}
                              value={moment(checkInDate).format("YYYY-MM-DD")}
                              required
                            />
                          </Form.Group>
                          <Form.Control
                            type="time"
                            aria-label="time"
                            disabled={disabled}
                            onChange={(e) => handleCheckInTime(e)}
                            value={moment(spt).format("HH:mm")}
                            required
                          />
                        </InputGroup>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label sm="2" className="col-sm-2">
                            Title*
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              name="Title"
                              value={Title}
                              onChange={(e) => onInputChange(e)}
                              required
                              as="textarea"
                              rows={2}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="form.Category"
                        >
                          <Form.Label column sm="2">
                            Category*
                          </Form.Label>
                          <Col sm="10 d-flex justify-content-left checkBoxNew">
                            <Form.Check
                              checked={category === "業績発表" ? true : false}
                              onChange={(e) => onInputChange(e)}
                              inline
                              label={"業績発表"}
                              name="category"
                              type="checkbox"
                            />{" "}
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="form.docid"
                        >
                          <Form.Label column sm="2">
                            DOC ID*
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              value={DocID}
                              readonly
                              disabled
                              required
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="form.browse"
                        >
                          <Form.Label column sm="2">
                            PDF File Upload*
                          </Form.Label>
                          <Col
                            sm="10 d-flex justify-content-left"
                            style={{ height: "30px" }}
                          >
                            <Form.Control
                              type="file"
                              accept="application/pdf"
                              name="file"
                              onChange={(e) => onChangeHandler(e)}
                            />
                          </Col>
                          <Col
                            sm="10 d-flex justify-content-left"
                            style={{ height: "30px", paddingLeft: "18%" }}
                          >
                            <Button
                              onClick={(e) => onClickHandler(e)}
                              class="btn btn-success btn-block"
                              variant="primary"
                              type="button"
                              style={{
                                lineHeight: "0",
                                height: "100%",
                                marginRight: "10%",
                              }}
                            >
                              Upload
                            </Button>{" "}
                            Uploaded File :
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => previewPDF(e)}
                            >
                              {user.FileName}
                            </a>{" "}
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="2">
                            Status
                          </Form.Label>
                          <Col sm="2">
                            {JSON.parse(
                              window.sessionStorage.getItem("statuss")
                            )}
                          </Col>
                        </Form.Group>
                        <div className="button">
                          <Link
                            className="btn btn-primary"
                            variant="primary"
                            type="cancel"
                            to="/jp/admin"
                          >
                            Cancel
                          </Link>{" "}
                          {statuses == "Draft" || statuses == "Scheduled" ? (
                            <Button
                              onClick={() => updateDraft(id)}
                              variant="primary"
                            >
                              Save as Draft
                            </Button>
                          ) : null}
                          {"   "}
                          <Button
                            variant="primary"
                            onClick={() => sendDataToNewTab(id)}
                          >
                            Preview
                          </Button>
                          {"   "}
                          <Button
                            onClick={() => publishData(id)}
                            variant="primary"
                            id="publishbtn"
                          >
                            Schedule
                          </Button>
                          {"   "}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="button mt-4 float-right">
                          <Button
                            onClick={() => backData(id)}
                            variant="primary"
                            type="button"
                            className=" mr-2"
                          >
                            Back
                          </Button>
                          <Button
                            onClick={() => publishData(id)}
                            variant="primary"
                          >
                            {JSON.parse(
                              window.sessionStorage.getItem("pageDatas")
                            ).status == "Published"
                              ? "Publish"
                              : "Schedule"}
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
