import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

class Login extends React.Component {
  componentDidMount() {
    const { login } = this.props;
    login();
  }
  render() {
    return <Loader />;
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
