import React from "react";
import EditUser from "components/users/EditUser";

const NewData = React.lazy(() => import("pages/NewData"));
const AdminData = React.lazy(() => import("pages/AdminData"));

const routes = [
  {
    enabled: true,
    path: "/jp/admin",
    component: AdminData,
    navbar: "Admin",
    child: null,
  },
  {
    enabled: true,
    path: "/jp/new",
    component: NewData,
    child: null,
  },
  {
    enabled: true,
    path: "/jp/edit/:id",
    component: EditUser,
    child: [],
  },
];

export default routes.filter((route) => route.enabled);
