import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";
import { authProvider } from "./AuthProvider";

class AuthCallBack extends React.Component {
  constructor(props) {
    super(props);
    const processLogin = () => {
      props.toggleState();
      props.history.push("/jp/admin");
      window.location.reload();
    };
    authProvider.handlePromiseRedirectCallback(processLogin);
  }

  async componentDidMount() {}

  render() {
    return <Loader />;
  }
}

AuthCallBack.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default AuthCallBack;
