import React from "react";

export default () => (
  <div className="loader">
    <div className="lds">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
