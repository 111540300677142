import React from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();

  return (
    <>
      <div className="row w-100 pt-4 mt-5">
        <div className="col mb-3 col-12 text-center">
          <h1>404</h1>
          <h2>Error Page</h2>
        </div>
        <div className="col text-center mt-4">
          <h3>Page you're trying to access is not available</h3>
          <Button
            variant="primary"
            className="cursor-pointer"
            onClick={() => history.push("/jp")}
          >
            Go to Home Page
          </Button>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
