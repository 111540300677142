import React, { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import configData from "../config.json";
import Header from "components/Header/header";
import Footer from "components/Footer/Footer";
import { authProvider } from "../components/AuthProvider";

const Preview = () => {
  const yearlist = [];
  let currentyear = new Date().getFullYear();

  for (var i = 0; i < 7; i++) {
    yearlist.push(currentyear - i);
  }
  
  let previewJSON = JSON.parse(window.localStorage.getItem("pageData"));
  let date = previewJSON.Release.split("-");

  const previewPDF = async (e) => {
    const { accessToken } = await authProvider.getGatewayAccessToken();
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${configData.previewPDF}?year=${previewJSON.Year}&filename=${previewJSON.FileName}`,
      config
    );
    var buffer, d, i, result;
    // 1. convert object to array
    result = [];
    for (i in response.data.Body.data) {
      d = response.data.Body.data[i];
      result.push(d);
    }
    // 2. convert that to a Uint8Array
    buffer = new Uint8Array(result);
    var blob = new Blob([buffer], { type: "application/pdf" });
    var link = window.URL.createObjectURL(blob);
    window.open(
      link,
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    );
  };

  return (
    <Fragment>
      {" "}
      <Header />
      <div className="page-container">
        <div className="container page-content">
          <div>
            <div className="row mt48">
              <div className="col-sm-12">
                <h1 id="mainheading">
                  プレスリリース
                  <br />
                  <sup id="headingtitle">ニュース 報道関係者向け</sup>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="p-2 col-lg-11 col-md-11 col-sm-12">
                  <div>
                    <h3>
                      {" "}
                      {date[0]}年{date[1]}月{date[2]}日
                    </h3>
                    <p>
                      <a
                        className="pdf latest-story"
                        href="javascript:void(0)"
                        onClick={(e) => previewPDF(e)}
                        style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "anywhere",
                          color: "#0056b3",
                          textDecoration: "underline",
                        }}
                      >
                        {JSON.parse(window.localStorage.getItem("pageData")).Title}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 d-none d-md-block">
                <ul className="side-navigation p-2">
                  {" "}
                  <li>
                    <a className="active" href="/">
                      {" "}
                      プレスリリース{" "}
                    </a>
                  </li>{" "}
                  {yearlist.map((comp) => (
                    <li>
                      <a href={"?year=" + comp}>バックナンバー{comp}年度</a>
                    </li>
                  ))}
                  <li>
                    <a href="?category=finance"> 業績発表 </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Preview;
